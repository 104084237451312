import FirebaseAnalytics from '@shamrock-core/common/analytics/firebase-analytics';

const ShamrockCoreConfig = require('ShamrockCoreConfig'); // eslint-disable-line @typescript-eslint/no-var-requires
FirebaseAnalytics.init(ShamrockCoreConfig.firebaseConfig);

const App = () => {
  return <>Bootstrapping component (do not use it)</>;
};

export default App;
